import { Box, Flex } from '@mantine/core';
import SearchBar from 'features/common/SearchBar';
import React, { useEffect, useState } from 'react';
import OrderShopItem from '../component/OrderShopItem';
import { useGetShopList } from 'apis/queries/shop';
import { ShopItem } from 'types';

const ShopContents = () => {
  const { data: shopList } = useGetShopList();
  const [shopResult, setShopResult] = useState<ShopItem[]>([]);

  useEffect(() => {
    if (shopList) {
      setShopResult(shopList.data);
    }
  }, [shopList]);

  const searchShop = (searchWord: string) => {
    if (!searchWord && shopList) {
      setShopResult(shopList.data);
      return;
    }

    setShopResult(
      shopList?.data.filter(shop => shop.name.includes(searchWord) || shop.description.includes(searchWord)) || [],
    );
  };

  return (
    <Box>
      <SearchBar placeholder="매장 검색" onSearch={searchShop} />
      <Flex direction="column" gap="10px" mt={15}>
        {shopResult?.map(shop => {
          return <OrderShopItem key={shop.id} shop={shop} />;
        })}
      </Flex>
    </Box>
  );
};

export default ShopContents;
