import { Avatar, Box, Flex, Text } from '@mantine/core';
import React, { MouseEventHandler } from 'react';
import { IconMug, IconDroplet } from '@tabler/icons-react';
import { IngredientItem } from 'types';

export interface AddedIngredientItemProps {
  ingredient: IngredientItem;
  isSelected: boolean;
  onClick?: MouseEventHandler;
}

const AddedIngredientItem = ({ ingredient, isSelected, onClick }: AddedIngredientItemProps) => {
  const coffeeTextList = ['coffee', 'shot', '커피', '샷', 'espresso', '에스프레소'];
  const isTypeInCoffee = ingredient.tags?.findIndex(tag => coffeeTextList.includes(tag)) !== -1;

  return (
    <Box
      className="added-ingredient-box"
      p="5px"
      c="black"
      bg={isSelected ? '#19070b08' : '#ffffff'}
      style={{ borderRadius: '10px' }}
      onClick={onClick}>
      <Flex justify="space-between" align="center" gap="10px" pr={10}>
        <Flex justify="space-between" align="center" gap="10px">
          <Avatar alt="재료 이미지" variant="light" src={ingredient.thumbnail} size="54px">
            {isTypeInCoffee ? <IconMug size="34px" /> : <IconDroplet size="34px" />}
          </Avatar>
          <Flex direction="column">
            <Text size="lg">{ingredient.name}</Text>
            <Text size="sm">{ingredient.description}</Text>
          </Flex>
        </Flex>
        <Text>{`${ingredient.price.toLocaleString('ko-KR')} 원`}</Text>
      </Flex>
    </Box>
  );
};

export default AddedIngredientItem;
