import { ActionIcon, TextInput, Image, TextInputProps } from '@mantine/core';
import React, { useRef } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';

export interface SearchBarProps {
  placeholder: string;
  onSearch: (searchWord: string) => void;
  showSearchButton?: boolean;
}

const SearchBar = ({ placeholder, onSearch, showSearchButton = true }: SearchBarProps) => {
  const searchBarRef = useRef<HTMLInputElement>(null);

  const handleSearch = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent) => {
    e.preventDefault;
    onSearch(searchBarRef.current?.value || '');
  }, 500);

  return (
    <TextInput
      placeholder={placeholder}
      w="100%"
      h="40px"
      size="md"
      radius="8px"
      ref={searchBarRef}
      onChange={handleSearch}
      rightSection={
        showSearchButton ? (
          <ActionIcon variant="transparent" color="#242424" onClick={handleSearch}>
            <Image src="assets/svg/IconSearch.svg" />
          </ActionIcon>
        ) : (
          <></>
        )
      }
    />
  );
};

export default SearchBar;
