import React, { ReactNode, useMemo, useState } from 'react';
import LayoutContext, { BottomButton } from './LayoutContext';

export interface LayoutProviderProps {
  children: ReactNode;
}

function LayoutProvider({ children }: LayoutProviderProps): JSX.Element {
  const [firstBottomButton, setFirstBottomButton] = useState<BottomButton | null>(null);
  const [secondBottomButton, setSecondBottomButton] = useState<BottomButton | null>(null);
  const [firstButtonDisable, setFirstButtonDisable] = useState<boolean>(false);

  const initializeBottomButtons = () => {
    setFirstBottomButton(null);
    setSecondBottomButton(null);
    setFirstButtonDisable(false);
  };

  const setBottomButtons = (buttons: [BottomButton, BottomButton | null]) => {
    setFirstBottomButton(buttons[0]);
    setSecondBottomButton(buttons[1]);
  };

  const changeFirstButtonDisable = (isDisable: boolean) => {
    setFirstButtonDisable(isDisable);
  };

  const layoutProviderValues = useMemo(() => {
    return {
      firstBottomButton,
      secondBottomButton,
      initializeBottomButtons,
      setBottomButtons,
      firstButtonDisable,
      changeFirstButtonDisable,
    };
  }, [
    firstBottomButton,
    secondBottomButton,
    initializeBottomButtons,
    setBottomButtons,
    firstButtonDisable,
    changeFirstButtonDisable,
  ]);

  return <LayoutContext.Provider value={layoutProviderValues}>{children}</LayoutContext.Provider>;
}

export default LayoutProvider;
