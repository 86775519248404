import { PaymentWidgetInstance, loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import { postOrder } from 'apis/order';
import { BottomButton } from 'features/layout/LayoutContext';
import useLayout from 'features/layout/useLayout';
import PaymentContents from 'features/payment/PaymentContents';
import { Place } from 'features/payment/types';
import { formatPrice } from 'features/payment/utils';
import React, { useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import useCartStore from 'store/useCartStore';

export interface PaymentPageProps {
  now?: boolean;
}

function PaymentPage({ now = false }: PaymentPageProps): JSX.Element {
  return <PaymentContents now={now} />;
}

export const loader = async () => {
  try {
    const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY ?? '';
    const customerKey = crypto.randomUUID();
    const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

    return { paymentWidget };
  } catch (err) {
    console.error(err);
  }
};

export default PaymentPage;
