import { ActionIcon, Avatar, Box, Flex, Text } from '@mantine/core';
import { IconMug, IconDroplet } from '@tabler/icons-react';
import useOrderStore from 'store/useOrderStore';
import { IngredientItem } from 'types';

export interface MenuIngredientItemProps {
  ingredient: IngredientItem;
  count: number;
}

const MenuIngredientItem = ({ ingredient, count }: MenuIngredientItemProps) => {
  const { addSelectedInfo, removeSelectedInfo } = useOrderStore();
  const coffeeTextList = ['coffee', 'shot', '커피', '샷', 'espresso', '에스프레소'];
  const isTypeInCoffee = ingredient.tags?.findIndex(tag => coffeeTextList.includes(tag)) !== -1;

  return (
    <Box p="8px">
      <Flex justify="space-between" align="center" gap="10px">
        <Flex justify="space-between" align="center" gap="10px">
          <Avatar alt="메뉴 이미지" src={ingredient.thumbnail} size="56px">
            {isTypeInCoffee ? <IconMug size="40px" /> : <IconDroplet size="40px" />}
          </Avatar>
          <Flex direction="column" justify="space-between">
            <Text size="lg">{ingredient.name}</Text>
            <Text>{`${ingredient.price.toLocaleString('ko-KR')} 원`}</Text>
          </Flex>
        </Flex>
        <Flex w="72px" justify="space-between">
          {count !== 0 ? (
            <ActionIcon
              variant="default"
              radius="xl"
              size="sm"
              onClick={() => {
                removeSelectedInfo(ingredient.id, 1);
              }}>
              <Text size="md">-</Text>
            </ActionIcon>
          ) : (
            <ActionIcon
              variant="default"
              radius="xl"
              size="sm"
              color="red"
              onClick={() => {
                removeSelectedInfo(ingredient.id);
              }}>
              <Text size="md" color="red">
                x
              </Text>
            </ActionIcon>
          )}
          <Text size="16px" style={{ lineHeight: '24px' }}>
            {count}
          </Text>
          <ActionIcon
            variant="default"
            radius="xl"
            size="24px"
            onClick={() => {
              addSelectedInfo(ingredient.id, 1);
            }}>
            <Text size="md">+</Text>
          </ActionIcon>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MenuIngredientItem;
