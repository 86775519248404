import { Grid, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { BottomButton } from 'features/layout/LayoutContext';
import useLayout from 'features/layout/useLayout';
import OrderList from 'features/payment/ToPayOrderList';
import { formatPrice, getTotalPrice } from 'features/payment/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFullPathById } from 'routes';
import useCartStore from 'store/useCartStore';

function PaymentDonePage() {
  const { cart } = useCartStore();
  const { setBottomButtons, initializeBottomButtons } = useLayout();
  const navigate = useNavigate();

  useEffect(() => {
    const bottomButton: BottomButton = {
      text: '홈으로 돌아가기',
      onClick: () => navigate(getFullPathById('HOME')),
    };

    setBottomButtons([bottomButton, null]);

    return () => initializeBottomButtons();
  }, []);

  return (
    <SimpleGrid cols={1} spacing={0}>
      <Stack justify="center" gap={8} classNames={{ root: 'text-center pb-[16px]' }}>
        <Title order={2}>{formatPrice(getTotalPrice(cart))} 결제 완료</Title>
        <Text>음료가 완성되면 알려드릴게요</Text>
        <Text>주문 번호: {}</Text>
      </Stack>
      <SimpleGrid cols={1} classNames={{ root: 'py-[16px]' }}>
        <header>
          <Title order={3}>주문 레시피</Title>
        </header>
        <OrderList orderList={cart} />
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default PaymentDonePage;
