import { Grid, SimpleGrid, Text, Title } from '@mantine/core';
import ToPayOrderList from './ToPayOrderList';
import OrderPlace from './OrderPlace';
import PaymentWidget from './PaymentWidget';
import { Place } from './types';
import useCartStore from 'store/useCartStore';
import { useLoaderData, useParams } from 'react-router-dom';
import useLayout from 'features/layout/useLayout';
import { PaymentWidgetInstance } from '@tosspayments/payment-widget-sdk';
import { useEffect, useState } from 'react';
import { BottomButton } from 'features/layout/LayoutContext';
import { formatPrice } from './utils';
import { postOrder } from 'apis/order';

export interface PaymentContentsProps {
  now?: boolean;
}

function PaymentContents({now = false}: PaymentContentsProps) {
  const { initializeBottomButtons, setBottomButtons } = useLayout();
  const { shopId } = useParams();
  const { paymentWidget } = useLoaderData() as { paymentWidget: PaymentWidgetInstance | undefined };
  const { cart, itemToPay, selectedShop, getSortedCart } = useCartStore();
  const [place, setPlace] = useState<Place>('TO_GO');
  const toPayOrderList = now ?  [itemToPay!] : getSortedCart();
  const totalPrice = toPayOrderList.reduce((acc, cur) => (acc += cur.priceSum * cur.quantity), 0);

  useEffect(() => {
    const payButton: BottomButton = {
      text: `${formatPrice(totalPrice)} 결제하기`,
      onClick: async () => {
        if (shopId === undefined) return;

        try {
          const order = await postOrder(shopId, {
            orders: cart.map(order => ({
              ingredientIds: order.ingredients.map(ingredient => ingredient.ingredient.id),
              packageType: place,
              temperatureType: order.temperatureType ? ('ICE' as const) : ('HOT' as const),
              sizeType: order.sizeType,
            }))[0],
          });
          await paymentWidget?.requestPayment({
            orderId: order.orderId,
            orderName: '...님의 주문',
            successUrl: `${window.location.origin}/${shopId}/payment/success`,
            failUrl: `${window.location.origin}/${shopId}/payment/fail`,
          });
        } catch (error) {
          console.error(error);
        }
      },
    };

    setBottomButtons([payButton, null]);

    return () => initializeBottomButtons();
  }, [place]);

  return (
    <SimpleGrid cols={1} spacing={0}>
      <Grid justify="space-between" align="center" classNames={{ root: 'pb-[16px]' }}>
        <Grid.Col span="content">
          <Title order={3}>주문 매장</Title>
        </Grid.Col>
        <Grid.Col span="content">
          <Text size="md">{selectedShop?.name}</Text>
        </Grid.Col>
      </Grid>
      <OrderPlace place={place} onChangePlace={(place) => setPlace(place)} />
      <SimpleGrid cols={1} classNames={{ root: 'py-[16px]' }}>
        <header>
          <Title order={3}>주문 레시피</Title>
        </header>
        <ToPayOrderList orderList={toPayOrderList} />
      </SimpleGrid>
      <SimpleGrid>
        <PaymentWidget price={totalPrice} />
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default PaymentContents;
