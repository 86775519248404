import { Button, Flex, Modal, Title } from '@mantine/core';
import ModalCheckStore from 'features/cart/ModalCheckStore';
import { useState } from 'react';
import useCartStore from 'store/useCartStore';
import { SizeType, TemperatureType } from 'types';

interface OrderPopupProps {
  shopId: string;
  onClose: (type: 'CART' | 'PAY', temperature: TemperatureType, size: SizeType) => void;
}

const MenuPopup = ({ onClose, shopId }: OrderPopupProps) => {
  const [temperature, setTemperature] = useState<TemperatureType | null>(null);
  const [size, setSize] = useState<SizeType | null>(null);
  const TEMPERATURE: { [key: string]: TemperatureType } = {
    Hot: 'HOT',
    Ice: 'ICE',
  } as const;
  const SIZE: { [key: string]: SizeType } = {
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    'Extra Large': 'EXTRA_LARGE',
  } as const;

  function selectTemperature(option: TemperatureType) {
    if (temperature === option) {
      setTemperature(null);
    } else {
      setTemperature(option);
    }
  }

  function selectSize(option: SizeType) {
    if (size === option) {
      setSize(null);
    } else {
      setSize(option);
    }
  }

  const { selectedShop } = useCartStore();
  const [modalOpened, setModalOpened] = useState(false);

  function addItemToCart() {
    if (selectedShop && selectedShop?.id !== Number(shopId)) {
      setModalOpened(true);
      return;
    }
    onClose('CART', temperature!, size!);
  }

  const isReady = temperature && size;

  return (
    <>
      <Flex direction="column" mt={15} gap={20}>
        <Title order={2}>음료 제작 옵션</Title>
        <Flex direction="column" gap={8}>
          <Title order={3}>Hot/Iced</Title>
          <Flex gap={8} wrap="wrap">
            {Object.keys(TEMPERATURE).map(option => (
              <Button
                variant={temperature === TEMPERATURE[option] ? 'filled' : 'light'}
                size="md"
                radius="md"
                key={option}
                onClick={() => selectTemperature(TEMPERATURE[option])}>
                {option}
              </Button>
            ))}
          </Flex>
        </Flex>
        <Flex direction="column" gap={8}>
          <Title order={3}>Size</Title>
          <Flex gap={8} wrap="wrap">
            {Object.keys(SIZE).map(option => (
              <Button
                variant={size === SIZE[option] ? 'filled' : 'light'}
                size="md"
                radius="md"
                key={option}
                onClick={() => selectSize(SIZE[option])}>
                {option}
              </Button>
            ))}
          </Flex>
        </Flex>

        <Flex mt={24} gap={16}>
          <Button size="lg" radius="md" fullWidth variant="outline" disabled={!isReady} onClick={addItemToCart}>
            장바구니 담기
          </Button>
          <Button
            size="lg"
            radius="md"
            fullWidth
            disabled={!isReady}
            onClick={() => onClose('PAY', temperature!, size!)}>
            바로 주문하기
          </Button>
        </Flex>
      </Flex>
      <Modal opened={modalOpened} onClose={() => setModalOpened(false)} withCloseButton={false} centered>
        <ModalCheckStore
          close={() => setModalOpened(false)}
          addCart={() => {
            onClose('CART', temperature!, size!);
          }}
        />
      </Modal>
    </>
  );
};

export default MenuPopup;
