import { Avatar, Grid, Stack, Text } from '@mantine/core';
import { formatPrice } from './utils';
import { CartOrderItem } from 'types';
import coffeeImg from 'assets/img/coffee_icon.png';

interface Props {
  order: CartOrderItem;
}

function ToPayOrderItem({ order }: Props) {
  return (
    <Grid key={order.orderId} align="center" pr={10}>
      <Grid.Col span="content">
        <Avatar size="4.5rem" src={coffeeImg} />
      </Grid.Col>
      <Grid.Col span="auto">
        <Stack gap={8}>
          <Text>{formatOrderInfo(order)}</Text>
          <Text>{formatPrice(Number(order.priceSum))}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={1}>{order.quantity}</Grid.Col>
    </Grid>
  );
}

const formatOrderInfo = (order: CartOrderItem) => {
  const iced = order.temperatureType;
  const size = order.sizeType;
  const ingredients = order.ingredients.map(ingredient => `${ingredient.ingredient.name} ${ingredient.quantity}`);

  return [iced, size, ...ingredients].join(' | ');
};

export default ToPayOrderItem;
