import { BottomButton } from 'features/layout/LayoutContext';
import useLayout from 'features/layout/useLayout';
import IngredientContents from 'features/order/content/IngredientContent';
import React, { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import useOrderStore from 'store/useOrderStore';

const IngredientPage = () => {
  const { setBottomButtons, initializeBottomButtons, changeFirstButtonDisable } = useLayout();
  const {selectedInfo} = useOrderStore();
  const orderPopup = useDisclosure();

  useEffect(() => {
    const bottomSheetButton: BottomButton = {
      text: '다음',
      onClick: () => orderPopup[1].open(),
    };

    setBottomButtons([bottomSheetButton, null]);

    return () => initializeBottomButtons();
  }, []);

  useEffect(() => {
    changeFirstButtonDisable(Object.keys(selectedInfo).length === 0);
  }, [selectedInfo]);

  return <IngredientContents orderPopupOpened={orderPopup[0]} closeOrderPopup={orderPopup[1].close} />;
};

export default IngredientPage;
