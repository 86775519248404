import axiosInstance from 'apis';
import { ApiListResponse } from './types';
import { IngredientItem, ShopItem } from 'types';
import { AxiosResponse } from 'axios';

export function getShopList(): Promise<ApiListResponse<ShopItem>> {
  return axiosInstance.get('/shop/list');
}

export function getShopById(shopId: number): Promise<AxiosResponse<ShopItem>> {
  return axiosInstance.get(`/shop/${shopId}`);
}

export const getIngredientList = async (shopId: number): Promise<ApiListResponse<IngredientItem>> => {
  return axiosInstance.get(`/shop/${shopId}/ingredients`);
};
