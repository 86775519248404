import { IngredientItem } from 'types';
import { create } from 'zustand';

interface State {
  ingredientInfo: { [key: string]: IngredientItem };
  selectedInfo: { [key: string]: number };
}

interface Handler {
  setIngredientInfo: (ingredients: IngredientItem[]) => void;
  addSelectedInfo: (id: string, count?: number) => void;
  removeSelectedInfo: (id: string, count?: number) => void;
  setSelectedInfo: (selectedList: string[]) => void;
}

export type StateType = State & Handler;

const initialState: State = {
  ingredientInfo: {},
  selectedInfo: {},
};

const useOrderStore = create<StateType>(set => ({
  ...initialState,
  setIngredientInfo: (ingredients: IngredientItem[]) => {
    set(state => {
      const ingredientsObj: { [key: string]: IngredientItem } = {};
      ingredients.forEach(ingredients => {
        ingredientsObj[ingredients.id] = ingredients;
      });
      state.ingredientInfo = ingredientsObj;
      return state.ingredientInfo;
    });
  },
  addSelectedInfo: (id: string, count?: number) =>
    set(state => {
      const newInfo = { ...state.selectedInfo };
      if (state.ingredientInfo[id] && !state.selectedInfo[id]) {
        newInfo[id] = 1;
      } else if (state.ingredientInfo[id] && count) {
        newInfo[id] += count;
      }
      state.selectedInfo = { ...newInfo };
      return state.selectedInfo;
    }),
  removeSelectedInfo: (id: string, count?: number) =>
    set(state => {
      if (state.selectedInfo[id] || state.selectedInfo[id] === 0) {
        const newInfo = { ...state.selectedInfo };
        if (count) {
          newInfo[id] -= count;
        } else {
          delete newInfo[id];
        }
        state.selectedInfo = { ...newInfo };
      }
      return state.selectedInfo;
    }),
  setSelectedInfo: (selectList: string[]) =>
    set(state => {
      const newInfo: { [key: string]: number } = {};
      selectList.forEach(id => (newInfo[id] = state.selectedInfo[id] ? state.selectedInfo[id] : 1));
      state.selectedInfo = { ...newInfo };
      return state.selectedInfo;
    }),
}));

export default useOrderStore;
