import { Button, Flex, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';

export interface AskGoCartPopupProps {
  onClose(): void;
}
const AskGoCartPopup = ({ onClose }: AskGoCartPopupProps) => {
  const navigate = useNavigate();

  function goToCart() {
    onClose();
    navigate(getFullPathById(ROUTE_PATHS.CART.id));
  }

  return (
    <Flex direction="column" justify="space-between" h="20vh">
      <Flex align="center" justify="center" h="60%">
        <Title order={3}>장바구니에 주문이 담겼어요!</Title>
      </Flex>
      <Flex mt={24} gap={16}>
        <Button size="lg" radius="md" fullWidth variant="outline" onClick={onClose}>
          계속 주문담기
        </Button>
        <Button size="lg" radius="md" fullWidth onClick={goToCart}>
          장바구니 이동
        </Button>
      </Flex>
    </Flex>
  );
};

export default AskGoCartPopup;
