import { ActionIcon, AppShell, Avatar, Button, Container, Group, Title } from '@mantine/core';
import { useHeadroom } from '@mantine/hooks';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useLayout from './useLayout';
import { IconShoppingCart } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';

function Layout(): JSX.Element {
  const pinned = useHeadroom({ fixedAt: 120 });
  const { firstBottomButton, secondBottomButton, firstButtonDisable } = useLayout();
  const navigate = useNavigate();

  function goHome() {
    navigate(getFullPathById(ROUTE_PATHS.HOME.id));
  }

  function goCart() {
    navigate(getFullPathById(ROUTE_PATHS.CART.id));
  }

  return (
    <AppShell header={{ height: 60, collapsed: !pinned }} footer={{ height: 60, offset: true }} padding="md">
      <AppShell.Header>
        <Group justify="space-between" h="100%" px="md">
          <Title order={2} onClick={goHome}>
            Tailored Coffee
          </Title>
          <Group justify="flex-end" gap="xs">
            <ActionIcon variant="white" color="gray" aria-label="cart" onClick={goCart}>
              <IconShoppingCart />
            </ActionIcon>
            <Avatar radius="xl" size="md" />
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      {firstBottomButton && (
        <AppShell.Footer withBorder={false}>
          <Container px="md">
            {secondBottomButton ? (
              <Group gap="sm" grow>
                <Button size="lg" radius="md" onClick={firstBottomButton.onClick}>
                  {firstBottomButton.text}
                </Button>
                <Button size="lg" radius="md" onClick={secondBottomButton.onClick}>
                  {secondBottomButton.text}
                </Button>
              </Group>
            ) : (
              <Button fullWidth size="lg" radius="md" onClick={firstBottomButton.onClick} disabled={firstButtonDisable}>
                {firstBottomButton.text}
              </Button>
            )}
          </Container>
        </AppShell.Footer>
      )}
    </AppShell>
  );
}

export default Layout;
