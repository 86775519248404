import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@mantine/core/styles.css';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MantineProvider, colorsTuple, createTheme } from '@mantine/core';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'routes';
import LayoutProvider from 'features/layout/LayoutProvider';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();
const router = createBrowserRouter(routes);

const theme = createTheme({
  primaryColor: 'dark-brown',
  colors: {
    'dark-brown': colorsTuple('#19070B'),
  },
  fontFamily: 'Pretendard',
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <MantineProvider theme={theme}>
        <LayoutProvider>
          <RouterProvider router={router} />
        </LayoutProvider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
