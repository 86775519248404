import { Button, Chip, ChipGroup, Flex, Group, Pill, Text } from '@mantine/core';
import SearchBar from 'features/common/SearchBar';
import useOrderStore from 'store/useOrderStore';
import AddedIngredientItem from './AddedIngredientItem';
import { useState } from 'react';

interface IngredientPopupProps {
  onClose: () => void;
}

const IngredientPopup = ({ onClose }: IngredientPopupProps) => {
  const { ingredientInfo, selectedInfo, setSelectedInfo } = useOrderStore();
  const [selectList, setSelectList] = useState<string[]>(Object.keys(selectedInfo));
  const [searchWord, setSearchWord] = useState<string>('');
  const tagList = Array.from(
    new Set(
      Object.values(ingredientInfo)
        .map(ingredient => ingredient.tags)
        .flat(),
    ),
  );
  const [selectedTag, setSelectedTag] = useState<string | string[]>('all');

  const addSelectList = (id: string) => {
    if (selectList.includes(id)) {
      removeSelectList(id);
      return;
    }

    setSelectList([...selectList, id]);
  };

  const removeSelectList = (id: string) => {
    if (selectList.includes(id)) {
      setSelectList([...selectList.filter(selected => selected !== id)]);
    }
  };

  const finishAdd = () => {
    setSelectedInfo([...selectList]);
    onClose();
  };

  const changeTag = (tag: string | string[]) => {
    setSelectedTag(tag);
  };

  const filteredIngredient = Object.values(ingredientInfo)
    .filter(ingredient => selectedTag === 'all' || ingredient.tags?.includes(selectedTag as string))
    .filter(ingredient => ingredient.name.includes(searchWord) || ingredient.description.includes(searchWord));

  return (
    <Flex direction="column" mt={15} data-autofocus>
      <SearchBar
        placeholder="재료 검색"
        onSearch={value => {
          setSearchWord(value);
        }}
        showSearchButton={false}
      />
      <ChipGroup value={selectedTag} onChange={changeTag}>
        <Group
          mx={5}
          mt={5}
          style={{
            background: '#FFFFFF',
            position: 'sticky',
            top: 0,
            left: 0,
            paddingTop: '15px',
            paddingBottom: '10px',
            zIndex: 100,
            flexWrap: 'nowrap',
            overflowX: 'scroll',
          }}>
          <Chip value="all">All</Chip>
          {tagList
            .filter(tag => !!tag)
            .map((tag, index) => (
              <Chip key={index} value={tag}>
                {tag}
              </Chip>
            ))}
        </Group>
      </ChipGroup>
      <Flex direction={'column'} gap={5} my={15}>
        {filteredIngredient.length === 0 ? (
          <Flex justify={'center'} align={'center'}>
            <Text>담을 수 있는 재료가 없습니다</Text>
          </Flex>
        ) : (
          filteredIngredient.map(ingredientInfo => {
            return (
              <AddedIngredientItem
                key={ingredientInfo.id}
                ingredient={ingredientInfo}
                isSelected={selectList.includes(ingredientInfo.id)}
                onClick={() => {
                  addSelectList(ingredientInfo.id);
                }}
              />
            );
          })
        )}
      </Flex>

      <div
        style={{
          background: '#ffffff',
          position: 'sticky',
          left: 0,
          bottom: 0,
          paddingBottom: '10px',
          border: 'none',
        }}>
        {selectList.length !== 0 && (
          <Flex gap="16px" mt={15} mb={10} pb={5} style={{ overflowX: 'scroll' }}>
            {selectList.map(id => (
              <Pill
                radius="sm"
                size="lg"
                color="#19070b1a"
                key={id}
                withRemoveButton
                onRemove={() => {
                  removeSelectList(id);
                }}>
                {ingredientInfo[id].name}
              </Pill>
            ))}
          </Flex>
        )}
        <Button variant="filled" size="lg" radius="md" onClick={finishAdd} fullWidth>
          <Text size="16px" ta="center">
            재료 담기
          </Text>
        </Button>
      </div>
    </Flex>
  );
};

export default IngredientPopup;
