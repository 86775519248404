import { Button, Flex, Group, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';
import useCartStore from 'store/useCartStore';

export interface ModalCheckStoreProps {
  close(): void;
  addCart(): void;
}

function ModalCheckStore({ close, addCart }: ModalCheckStoreProps): JSX.Element {
  const navigate = useNavigate();

  const { resetCart } = useCartStore();

  function handleClickAddCart() {
    resetCart();
    addCart();
  }

  return (
    <>
      <Flex direction={'column'} mt={20}>
        <Text ta="center" style={{ wordBreak: 'keep-all' }}>
          장바구니에는 같은 가게의 상품만 담을 수 있습니다. &nbsp;
          <Text size="xs" c={'gray'} span onClick={() => navigate(getFullPathById(ROUTE_PATHS.CART.id))}>
            장바구니 보러가기 &gt;
          </Text>
        </Text>
      </Flex>
      <Group gap="sm" grow mt={30}>
        <Button onClick={close}>취소</Button>
        <Button onClick={handleClickAddCart}>담기</Button>
      </Group>
    </>
  );
}

export default ModalCheckStore;
