import React, { useState } from 'react';
import { Flex, Text, Image, Avatar, Modal } from '@mantine/core';
import locationImg from 'assets/img/location-icon.png';
import coffeeImg from 'assets/img/coffee_icon.png';
import { OrderItem } from 'types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';
import useCartStore from 'store/useCartStore';
import ModalCheckStore from 'features/cart/ModalCheckStore';
export interface LastOrderItemProps {
  item: OrderItem;
}

function LastOrderItem({ item }: LastOrderItemProps): JSX.Element {
  const formatIngredients = () => {
    return item.ingredients.map((orderedIngredient, index) => {
      const isLast = index === item.ingredients.length - 1;
      return (
        <Text key={orderedIngredient.ingredient.name}>
          {`${orderedIngredient.ingredient.name} ${orderedIngredient.quantity}${!isLast ? ' |' : ''}`}&nbsp;
        </Text>
      );
    });
  };

  const formatOrderedTime = () => {
    const now = new Date();
    const createdDate = new Date(item.createdDate!);

    const betweenTime = Math.floor((now.getTime() - createdDate.getTime()) / 1000 / 60);
    if (betweenTime < 1) return '지금';
    if (betweenTime < 60) {
      return `${betweenTime}분전`;
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간전`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 365) {
      return `${betweenTimeDay}일전`;
    }

    return `${Math.floor(betweenTimeDay / 365)}년전`;
  };

  const { addCart, selectedShop, setSelectedShop } = useCartStore();
  const navigate = useNavigate();

  function handleClickLastOrderItem() {
    if (selectedShop && selectedShop.id !== item.shopId) {
      setModalOpened(true);
      return;
    }
    addItemToCart();
  }

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  function addItemToCart() {
    addCart({ ...item, quantity: 1, addedTime: new Date() });
    setSelectedShop(item.shop!);
    navigate(getFullPathById(ROUTE_PATHS.CART.id));
  }

  return (
    <>
      <Flex direction="row" align="center" gap={16} onClick={handleClickLastOrderItem}>
        <Avatar size="4.5rem" src={coffeeImg} />
        <Flex direction="column" justify="center">
          <Text fw={700}>{formatOrderedTime()}</Text>
          <Flex wrap="wrap">
            <Text>{item.temperatureType} |&nbsp;</Text>
            {formatIngredients()}
          </Flex>
          <Flex gap={4} align="baseline">
            <Image h={12} src={locationImg} />
            <Text>{item.shop?.name || item.shopId}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Modal opened={modalOpened} onClose={() => setModalOpened(false)} withCloseButton={false} centered>
        <ModalCheckStore close={() => setModalOpened(false)} addCart={addItemToCart} />
      </Modal>
    </>
  );
}

export default LastOrderItem;
