import { Box, Button, Flex, Title } from '@mantine/core';
import React from 'react';
import CartItem from 'features/cart/CartItem';
import useCartStore from 'store/useCartStore';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, getFullPathById } from 'routes';

const CartContents = () => {
  const { cart, getSortedCart, selectedShop } = useCartStore();
  const totalPrice = cart.reduce((total, order) => {
    return (total += Number(order.priceSum) * order.quantity);
  }, 0);

  const navigate = useNavigate();

  return (
    <Box>
      <Flex direction="column" justify="space-between" mih="82vh" gap={28} pb={20}>
        <Flex direction="column" gap="1.6rem" style={{ overflow: 'auto' }}>
          <Title order={3}>담은 레시피 - {selectedShop?.name}</Title>
          <Flex direction="column" gap="1.6rem">
            {getSortedCart().map(order => {
              return <CartItem item={order} key={order.orderId} />;
            })}
          </Flex>
          <Button
            fullWidth
            size="lg"
            radius="md"
            variant="light"
            onClick={() => navigate(getFullPathById(ROUTE_PATHS.INGREDIENT.id, { shopId: String(selectedShop!.id) }))}>
            +
          </Button>
        </Flex>
        <Flex direction="column" align="flex-end">
          <Title order={2}>{`${totalPrice.toLocaleString('ko-KR')} 원`}</Title>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CartContents;
